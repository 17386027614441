import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import LanguageIcon from '@mui/icons-material/Language';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import ForumIcon from '@mui/icons-material/Forum';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AirplaneTicket from '@mui/icons-material/AirplaneTicket';

import KioskButton from '../theme/Button';
import LanguageSelectionDialog from '../components/LanguageSelection';
import Footer from '../components/Footer';
import Dialog from '../components/Dialog';
import { useStateValue } from '../store';

import logo from '../logo.svg';
import './index.css';

function App() {
    const [openDialog, setOpenDialog] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [{ languages }] = useStateValue();

    // dropdown language upper right
    const languageSelected = languages.data.find((lng) => lng.value === languages.defaultLanguage);

    return (
        <div className="App">
            <header className="App-header">
                <div className="Header-language-option">
                    <h2>
                        <FormattedMessage
                            id="selectLanguage"
                            defaultMessage="Please select your language"
                        />
                    </h2>
                    <Button
                        fullWidth={true}
                        sx={{ color: 'white', fontSize: 20 }}
                        startIcon={<img src={languageSelected.img} width={20} alt="bandera" />}
                        endIcon={<ArrowDropDownIcon />}
                        onClick={() => setOpenDialog(true)}
                    >
                        {languageSelected.label}
                    </Button>
                </div>
                <div className="Header-logo">
                    <Button href="/">
                        <img src={logo} className="App-logo" alt="logo" />
                    </Button>
                </div>
            </header>
            <main className="App-main">
                <h1>
                    <FormattedMessage
                        id="helpSubtitle"
                        defaultMessage="How can we help you today?"
                    />
                </h1>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#1fb729"
                        size="large"
                        startIcon={<AirplaneTicket />}
                        component={RouterLink}
                        to="/multilingual-airlines"
                    >
                        <FormattedMessage id="airlineAgents" defaultMessage="Airline agents" />
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#1ECBF6"
                        size="large"
                        startIcon={<TrendingUpIcon />}
                        href={`https://forms.worldwidetechconnections.com/user-forms/public-form/a3e52945?lang=${languages.defaultLanguage}`}
                    >
                        <FormattedMessage
                            id="denSurvey"
                            defaultMessage="DEN Customer Service Survey"
                        />
                    </KioskButton>
                </div>

                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#E652C3"
                        size="large"
                        startIcon={<LanguageIcon />}
                        href={`http://airportcall.worldwidetechconnections.com?local=${languages.defaultLanguage}&showKeypad=true&showInstructions=true`}
                    >
                        <FormattedMessage
                            id="multilingualCalling"
                            defaultMessage="Multilingual calling"
                        />
                    </KioskButton>
                </div>

                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#E652C3"
                        size="large"
                        startIcon={<LanguageIcon />}
                        href={`http://airportcall.worldwidetechconnections.com?local=${languages.defaultLanguage}&remote=english-united-states&showKeypad=true&showInstructions=true&freeCalling=true`}
                    >
                        <FormattedMessage id="freeCalling" defaultMessage="Free Calling" />
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#F67C1E"
                        size="large"
                        startIcon={<MedicalServicesIcon />}
                        //component={RouterLink}
                        //to="/health-wellness"
                        onClick={() => setOpenModal(true)}
                    >
                        <FormattedMessage
                            id="healthWellness"
                            defaultMessage="Health and Wellness"
                        />
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#8F8F8F"
                        size="large"
                        startIcon={<HeadsetMicIcon />}
                        disabled
                    >
                        <FormattedMessage id="denLiveVideo" defaultMessage="DEN Live Video Agent" />
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#F6BD1E"
                        size="large"
                        startIcon={<ForumIcon />}
                        disabled
                    >
                        <FormattedMessage
                            id="multilingualChatRoom"
                            defaultMessage="Multilingual Chat Room"
                        />
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#2EB777"
                        size="large"
                        startIcon={<ShoppingCartIcon />}
                        disabled
                    >
                        <FormattedMessage
                            id="denMarketplace"
                            defaultMessage="DEN Online Marketplace"
                        />
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#C72929"
                        size="large"
                        startIcon={<QrCode2Icon />}
                        disabled
                    >
                        <FormattedMessage
                            id="qrCodeCreator"
                            defaultMessage="QR Code Accesible Creator"
                        />
                    </KioskButton>
                </div>
            </main>
            <Footer />
            <LanguageSelectionDialog open={openDialog} handleOpen={setOpenDialog} />
            <Dialog open={openModal} handleClose={() => setOpenModal(false)} />
        </div>
    );
}

export default App;
